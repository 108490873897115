import { SwiperOptions } from 'swiper'

import { vars } from '~/statics'

const { gaps, breakpoints } = vars
const { productsCarousel: { mobile: gapsMobile, tablet: gapsTablet, cmsTablet } } = gaps
const { smallMobile, mobile, tablet, smallDesktop, desktop, largeDesktop } = breakpoints

export const CMS_BLOCK_ONE_ROW_PRODUCTS_CAROUSEL_PARAMS: SwiperOptions = {
	slidesPerView: 'auto',
	slidesPerGroup: 2,
	spaceBetween: gapsMobile,
	breakpoints: {
		[smallMobile]: {
			slidesPerView: 'auto',
			slidesPerGroup: 2,
			spaceBetween: gapsMobile,
		},
		[mobile]: {
			slidesPerView: 'auto',
			slidesPerGroup: 2,
			spaceBetween: gapsMobile,
		},
		[tablet]: {
			slidesPerView: 'auto',
			slidesPerGroup: 3,
			spaceBetween: 0,
		},
		[smallDesktop]: {
			slidesPerView: 2,
			slidesPerGroup: 2,
			spaceBetween: 0,
		},
		[desktop]: {
			slidesPerView: 2,
			slidesPerGroup: 2,
			spaceBetween: 0,
		},
		[largeDesktop]: {
			slidesPerView: 2,
			slidesPerGroup: 2,
			spaceBetween: 0,
		},
	},
}

import React, { ReactElement } from 'react'

import { useFooterNavigation } from '~/hooks/footerNavigation'
import { FooterBottomBar, FooterWrapper, AdditionalFooterWrapper } from '~/components/core/layout'
import { Newsletter } from '~/components/core/newsletter'

import styles from './FooterSimple.module.scss'

const FooterSimple = (): ReactElement => {
	const { policyLinks } = useFooterNavigation()

	return (
		<section className={ styles.wrapper }>
			<AdditionalFooterWrapper>
				<Newsletter />
			</AdditionalFooterWrapper>

			<FooterWrapper>
				<FooterBottomBar policyLinks={ policyLinks } theme={ { wrapper: styles.footer } } />
			</FooterWrapper>
		</section>
	)
}

export { FooterSimple }

import React, { ReactElement, useEffect, useMemo } from 'react'
import Head from 'next/head'
import { Store } from 'redux'
import { Provider } from 'react-redux'
import { appWithTranslation, useTranslation } from 'next-i18next'
import NextNprogress from 'nextjs-progressbar'
import { useRouter } from 'next/router'
import { isUndefined, isEqual } from 'lodash'

import { AppPropsWithLayout } from '~/components/core/layout/types'
import { SeoTags } from '~/components/core/seoTags'
import { ExternalScripts } from '~/components/core/externalScripts'
import { ReactErrorBoundary, ERROR_BOUNDARY_KEYS } from '~/components/core/reactErrorBoundary'
import { vars } from '~/statics/variables'
import { useStore } from '~/state/store'
import { importDayjsLocale, importReactDatepickerLocale } from '~/utils/locale'
import { UserProvider } from '~/providers/userProvider'
import { FlatTreeProvider } from '~/providers/flatTreeProvider'
import { AppParametersProvider } from '~/providers/appParametersProvider'
import { InitialParamsProvider } from '~/providers/initialParamsProvider'
import { StoreServicesProvider } from '~/providers/storeServicesProvider'
import { PageTypeProvider } from '~/providers/pageTypeProvider'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'

import '~/styles/globalStyles.scss'

const App = (props: AppPropsWithLayout): ReactElement => {
	const { Component, pageProps } = props
	const { nodes, appParams, initialReduxState, storeServices, initialParams, seoData, pageType, adeoAuthData } = pageProps
	const { isKobiIntegrationEnabled: isKobiIntegrationEnabledByEnv } = vars

	const { GA_isCatalogContext, GA_contentGroup, GA_testSiteLegacy } = useGoogleAnalytics()
	const { i18n } = useTranslation()
	// TODO: remove initial state value
	const store: Store = useStore({ ...initialReduxState })
	const router = useRouter()

	const appParamsValue = useMemo(() => appParams, [])
	const storesServicesValue = useMemo(() => storeServices, [])
	const initialParamsValue = useMemo(() => initialParams, [])
	const nodesValue = useMemo(() => nodes, [])

	const getLayout = Component.getLayout ?? ((page: ReactElement) => page)
	const isKobiIntegrationEnabledFromApi = appParamsValue?.kobiConfig?.integrationEnabled

	useEffect(() => {
		(async () => {
			await importDayjsLocale(i18n.language)
			await importReactDatepickerLocale(i18n.language)
		})()
	}, [i18n.language])

	useEffect(() => {
		if (!GA_isCatalogContext(pageType)) {
			GA_contentGroup(pageType)
		}
	}, [pageType])

	useEffect(() => {
		const oneTrustInterval = setInterval(() => {
			if (window.OneTrust?.OnConsentChanged) {
				clearInterval(oneTrustInterval)
			}
		}, 300)
	}, [])

	useEffect(() => {
		GA_testSiteLegacy(isUndefined(isKobiIntegrationEnabledByEnv) ? isKobiIntegrationEnabledFromApi : isEqual(isKobiIntegrationEnabledByEnv, 'true'))
	}, [isKobiIntegrationEnabledFromApi])

	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
			</Head>

			<ReactErrorBoundary key={ ERROR_BOUNDARY_KEYS.APP } url={ router.asPath }>
				<Provider store={ store }>
					<AppParametersProvider value={ appParamsValue }>
						<PageTypeProvider value={ pageType }>
							<UserProvider value={ adeoAuthData }>
								<FlatTreeProvider value={ nodesValue }>
									<InitialParamsProvider value={ initialParamsValue }>
										<StoreServicesProvider value={ storesServicesValue }>
											{ /* eslint-disable-next-line react/jsx-max-depth, react/jsx-newline */ }
											<NextNprogress
												height={ 5 }
												color={ vars.colors.green }
												options={ { showSpinner: false } }
												startPosition={ 0.2 }
											/>

											{ /* eslint-disable-next-line react/jsx-max-depth, react/jsx-newline */ }
											<SeoTags seoData={ seoData } />

											{ /* eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-newline */ }
											{ getLayout(<Component { ...pageProps } />) }
										</StoreServicesProvider>
									</InitialParamsProvider>
								</FlatTreeProvider>
							</UserProvider>
						</PageTypeProvider>
					</AppParametersProvider>
				</Provider>

				<ExternalScripts />
			</ReactErrorBoundary>
		</>
	)
}

export default appWithTranslation(App)

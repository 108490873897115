import React, { ReactElement } from 'react'

import { ICmsBlockHtmlProps } from '~/components/core/cmsBlock'
import { HtmlView } from '~/components/core/htmlView'

const CmsBlockHtml = (props: ICmsBlockHtmlProps): ReactElement => {
	const { content } = props

	return (
		<HtmlView content={ content } />
	)
}

export { CmsBlockHtml }

import React, { ReactElement, useContext } from 'react'

import { HeaderWrapper, HeaderTopBar, HeaderMenu, HeaderCmsSlot, IHeaderProps } from '~/components/core/layout'
import { SkipContentList } from '~/components/core/skipContent'
import { Notifications } from '~/components/core/notifications'
import { useSkipElements } from '~/hooks/skipElements'
import { AppParametersContext } from '~/providers/appParametersProvider'

const Header = (props: IHeaderProps): ReactElement => {
	const { pageType } = props
	const { topCmsSlot } = useContext(AppParametersContext)
	const { headerSkip } = useSkipElements(undefined, pageType)

	return (
		<HeaderWrapper>
			<SkipContentList skipElements={ headerSkip } />

			<HeaderCmsSlot topCmsSlot={ topCmsSlot } />

			<HeaderTopBar />

			<HeaderMenu pageType={ pageType } />

			<Notifications />
		</HeaderWrapper>
	)
}

export { Header }

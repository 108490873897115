import React, { ReactElement, MouseEvent, useCallback, useEffect, useState, useContext } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import InnerHTML from 'dangerously-set-html-content'
import { isEmpty, isNull, map } from 'lodash'

import { IHtmlViewProps, handleHtmlViewLink, getHtmlWithoutScripts } from '~/components/core/htmlView'
import { AppParametersContext } from '~/providers/appParametersProvider'

import styles from './HtmlView.module.scss'

const HtmlView = (props: IHtmlViewProps): ReactElement => {
	const {
		content,
		onLinkClick = undefined,
		additionalClass = '',
		...restProps
	} = props
	const router = useRouter()
	const [htmlContent, setHtmlContent] = useState('')
	const { kobiConfig: { integrationEnabled: isKobiIntegrationEnabled } } = useContext(AppParametersContext)

	const data = !isEmpty(htmlContent) ? htmlContent : content

	const handleClick = useCallback(async (event: MouseEvent): Promise<void> => {
		const element = event.target as HTMLElement

		switch (element.tagName) {
			case 'A': {
				const link = element.getAttribute('href')
				await handleHtmlViewLink({ link, event, router, onLinkClick, isKobiIntegrationEnabled })
			}
				break
			default: {
				if (!isNull(element.parentElement)) {
					const link = element.parentElement.getAttribute('href')
					await handleHtmlViewLink({ link, event, router, isKobiIntegrationEnabled })
				}
			}
		}
	}, [onLinkClick])

	const renderScripts = useCallback((): ReactElement[] => {
		const scriptsContent = data.match(/<script[\s\S]*?>[\s\S]*?<\/script>/gi)
		const scriptsElements: ReactElement[] = []

		if (!isNull(scriptsContent)) {
			map(scriptsContent, (script: string, index: number) => {
				scriptsElements.push(<InnerHTML key={ index } html={ script } />)
			})
		}

		return scriptsElements
	}, [data])

	const wrapperClass = classNames(styles.wrapper, {
		[additionalClass]: !isEmpty(additionalClass),
	})

	const htmlWithoutScripts = getHtmlWithoutScripts(content)

	useEffect(() => {
		setHtmlContent(data)
	}, [content])

	return (
		<>
			<div
				// eslint-disable-next-line react/jsx-props-no-spreading
				{ ...restProps }
				dangerouslySetInnerHTML={ { __html: htmlWithoutScripts } }
				role="presentation"
				className={ wrapperClass }
				onClick={ handleClick }
			/>

			{ renderScripts() }
		</>
	)
}

export { HtmlView }

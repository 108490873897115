import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useWindowScroll } from 'react-use'
import classNames from 'classnames'
import { map } from 'lodash'

import { ICmsPageBlockData } from '~/api/dataTypes/cms'
import { IHeaderCmsSlotProps } from '~/components/core/layout'
import { CmsBlock } from '~/components/core/cmsBlock'

import styles from './HeaderCmsSlot.module.scss'

const HeaderCmsSlot = (props: IHeaderCmsSlotProps): ReactElement => {
	const { topCmsSlot } = props
	const { y } = useWindowScroll()
	const [isHeaderFixed, setIsHeaderFixed] = useState<boolean>(false)

	const { blockList } = topCmsSlot

	const renderBlocks = useCallback((): ReactElement[] => (
		map(blockList, (block: ICmsPageBlockData): ReactElement => {
			const { content, name, type } = block

			return (
				<CmsBlock
					key={ name }
					content={ content }
					type={ type }
					name={ name }
				/>
			)
		})
	), [blockList])

	useEffect(() => {
		setIsHeaderFixed(y > 0)
	}, [y])

	const wrapperClass = classNames({
		[styles.wrapper]: true,
		[styles.hidden]: isHeaderFixed,
	})

	return (
		<div className={ wrapperClass }>
			{ renderBlocks() }
		</div>
	)
}

export { HeaderCmsSlot }

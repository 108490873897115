import { CartStatusType } from '~/api/dataTypes/cart'

export const PAGE_TYPE_NAME = {
	MAIN_PAGE: 'strona glowna',
	PRODUCT_CARD: 'karta produktu',
	PRODUCT_LIST: 'listing produktow',
	SEARCH_PRODUCTS: 'wyszukiwarka',
	SUB_WORLD_PRODUCT_LIST: 'listing produktow z podswiata',
	FAMILY_LEAF: 'rodzina produktow',
	CMS: 'strona cms',
	MEGA_WORLD: 'megaswiat',
	WORLD: 'swiat',
	SUB_WORLD: 'podswiat',
	REGISTRATION: 'rejestracja',
	STORES: 'sklepy',
	STORE_PAGE: 'sklep',
	SHOPPING_LIST: 'schowek',
	CLIENT_AREA: 'konto klienta',
	CLIENT_ORDERS: 'zamowienia klienta',
	CART: 'sciezka zakupowa - koszyk',
	CART_DELIVERY: 'sciezka zakupowa - wybor dostawy',
	CART_IDENTIFICATION: 'sciezka zakupowa - klient',
	CART_FINALIZE: 'sciezka zakupowa - finalizacja',
	CART_SUMMARY: 'sciezka zakupowa - podsumowanie',
	CONTACT: 'kontakt',
	NEWSPAPER: 'gazetka',
	OLD_NEWSPAPER: 'gazetka',
	DOM_PROGRAM: 'program dom',
	STATUS_404: 'strona bledu',
	STATUS_500: 'strona bledu',
	PRZELEWY24_ERROR: 'przelewy24 error',
	LOGIN: 'logowanie',
	PASSWORD_RECOVERY: 'przypomnienie hasla',
	MAIN_VARIANT_A: 'megaswiat',
	CART_FINISH: 'sciezka zakupowa - po zaplaceniu',
	ARTICLE: 'artykul',
	PRZELEWY24_SUCCESS: 'przelewy24 - sukces',
	INSPIRATION_ROOT: 'inspiracje',
	INSPIRATION: 'inspiracja',
	INSPIRATION_CATEGORY: 'kategoria inspiracji',
}

export const CART_STATUS_NAME: Record<CartStatusType, string> = {
	ZERO: 'sciezka zakupowa - koszyk',
	VIEW: 'sciezka zakupowa - koszyk',
	IDENTIFICATION: 'sciezka zakupowa - identyfikacja',
	CUSTOMER: 'sciezka zakupowa - klient',
	SUMMARY: 'sciezka zakupowa - podsumowanie',
	FINALIZE: 'sciezka zakupowa - finalizacja',
}

import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { isEmpty, isUndefined } from 'lodash'

import { SectionHeader } from '~/components/core/sectionHeader'
import { ICmsBlockProductTitleProps } from '~/components/core/cmsBlock'

import styles from './CmsBlockProductTitle.module.scss'

const CmsBlockProductTitle = (props: ICmsBlockProductTitleProps): ReactElement | null => {
	const { title, hideHeader, labelUrl, labelName } = props
	const { t } = useTranslation(['common'])

	if (isUndefined(title) || hideHeader) {
		return null
	}

	const headerParams = !isEmpty(labelUrl) && { linkTitle: !isEmpty(labelName) ? labelName : t('allProducts'), linkUrl: labelUrl }

	return (
		<div className={ styles.wrapper }>
			<SectionHeader
				title={ title }
				theme={ { title: styles.title } }
				// eslint-disable-next-line react/jsx-props-no-spreading
				{ ...headerParams }
			/>
		</div>
	)
}

export { CmsBlockProductTitle }

import React, { ReactElement, useCallback } from 'react'
import { isEqual } from 'lodash'

import { CmsBlockProductListing, CmsBlockProductTwoRows, CmsBlockProductOneRow, CmsBlockProductBig, ICmsBlockProductProps } from '~/components/core/cmsBlock'
import { CmsPageBlockDataSizeType } from '~/api/dataTypes/cms'

const CmsBlockProduct = (props: ICmsBlockProductProps): ReactElement | null => {
	const { content } = props

	const { displayType, imageBlockSize } = content

	const renderContent = useCallback((): ReactElement | null => {
		switch (displayType) {
			case 'TILES_VERTICAL_RWD':
				return <CmsBlockProductListing content={ content } />
			case 'TILES_VERTICAL_WITH_PRODUCT_RWD':
				return <CmsBlockProductBig content={ content } />
			case 'TILES_VERTICAL_WITH_IMAGE_RWD':
				return isEqual(imageBlockSize, 'TWO_ROWS_TWO_COLUMNS' as CmsPageBlockDataSizeType) ? (
					<CmsBlockProductTwoRows content={ content } />
				) : (
					<CmsBlockProductOneRow content={ content } />
				)
			default:
				return null
		}
	}, [content])

	return (
		<div>
			{ renderContent() }
		</div>

	)
}

export { CmsBlockProduct }

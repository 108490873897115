import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { ProductBlockButton, ProductBlockImage, ProductBlockName, ProductBlockPrice } from '~/components/core/productBlock'
import { ICmsBlockProductBlockProps } from '~/components/core/cmsBlock'
import { WishList } from '~/components/core/wishList'

import styles from './CmsBlockProductBlock.module.scss'

const CmsBlockProductBlock = (props: ICmsBlockProductBlockProps): ReactElement => {
	const {
		product,
		position = 0,
		additionalClass = '',
		overrideImageSrc = '',
	} = props

	const { price, lmReference, promotionLabel } = product

	const wrapperClass = classNames(styles.wrapper, additionalClass, 'product')

	return (
		<div className={ wrapperClass } data-lm-reference={ lmReference }>
			<div className={ styles.topWrapper }>
				<WishList product={ product } additionalClass={ styles.wishList } />

				<ProductBlockImage
					product={ product }
					newPricePromoLabel={ price.newPricePromoLabel }
					overrideImageSrc={ overrideImageSrc }
					position={ position }
					theme={ {
						link: styles.imageWrapper,
					} }
				/>

				<ProductBlockName product={ product } position={ position } />
			</div>

			<div>
				<ProductBlockPrice price={ price } promotionLabelCode={ promotionLabel?.code } />

				<ProductBlockButton product={ product } theme={ { button: styles.button } } />
			</div>
		</div>
	)
}

export { CmsBlockProductBlock }

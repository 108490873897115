import { useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { compact, isNull } from 'lodash'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { IFooterNavigationData, IFooterNavigationApp, IFooterNavigationBanner, IFooterNavigationButton, IFooterNavigationSection, IFooterNavigationPolicyLink } from '~/hooks/footerNavigation'
import { useMounted } from '~/hooks/mounted'
import { IRootState } from '~/state/types'

export const useFooterNavigation = (): IFooterNavigationData => {
	const { urls, imageUrls } = useContext(AppParametersContext)
	const { t } = useTranslation(['footer'])
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { isMounted } = useMounted()

	const getBanners = useCallback((): IFooterNavigationBanner[] => [
		{
			id: 'hero-of-the-house',
			label: t('banners.houseHero'),
			url: urls.heroOfTheHouse,
			imageUrl: imageUrls.homeHeroImage,
		},
		{
			id: 'foundation',
			label: t('banners.foundation'),
			url: urls.foundation,
			imageUrl: imageUrls.foundationImage,
		},
		{
			id: 'football-national-team',
			label: t('banners.footballNationalTeam'),
			url: urls.pzpn,
			imageUrl: imageUrls.footballTeamSponsorImage,
		},
	], [urls, imageUrls])

	const getApps = useCallback((): IFooterNavigationApp[] => [
		{
			id: 'google-play-store',
			url: urls.googlePlayStore,
			imageUrl: imageUrls.gplayImage,
			name: 'Google Play',
		},
		{
			id: 'ios-store',
			url: urls.iosStore,
			imageUrl: imageUrls.appStoreImage,
			name: 'App Store',
		},
	], [urls, imageUrls])

	const getButtons = useCallback((): IFooterNavigationButton[] => compact([
		{
			id: 'price-bargains',
			label: t('buttons.priceBargains'),
			url: urls.productsStorePromotions,
			pageType: 'SEARCH_PRODUCTS',
		},
		{
			id: 'check-out-services',
			label: t('buttons.checkOutServices'),
			url: urls.services,
			pageType: 'SERVICES',
		},
		{
			id: 'design-interior',
			label: t('buttons.designInterior'),
			url: urls.designService,
			pageType: 'CMS',
		},
		isNull(preferredStore) && isMounted && {
			id: 'online-sales',
			label: t('buttons.onlineSales'),
			url: urls.productsSales,
			pageType: 'SEARCH_PRODUCTS',
		},
	]), [urls, preferredStore, isMounted])

	const getPolicyLinks = useCallback((): IFooterNavigationPolicyLink[] => ([
		{
			id: 'ecommerce-terms',
			label: t('policyLinks.ecommerceTerms'),
			url: urls.ecommerceTerms,
			pageType: 'CMS',
		},
		{
			id: 'privacy-policy',
			label: t('policyLinks.privacyPolicy'),
			url: urls.privacyPolicy,
			pageType: 'CMS',
		},
		{
			id: 'legal-disclaimer',
			label: t('policyLinks.legalDisclaimer'),
			url: urls.legalDisclaimer,
			pageType: 'CMS',
		},
	]), [urls])

	const getSections = useCallback((): IFooterNavigationSection[] => [
		{
			id: 'lm-caption',
			name: t('sections.lm.caption'),
			items: [
				{
					id: 'lm-carrier',
					name: t('sections.lm.carrier'),
					url: urls.carrier,
				},
				{
					id: 'about-company',
					name: t('sections.lm.aboutCompany'),
					url: urls.aboutCompany,
				},
				{
					id: 'our-brands',
					name: t('sections.lm.ourBrands'),
					url: urls.ourBrands,
					pageType: 'CMS',
				},
				{
					id: 'sustainable-development',
					name: t('sections.lm.sustainableDevelopment'),
					url: urls.sustainableDevelopment,
				},
				{
					id: 'press-room',
					name: t('sections.lm.pressRoom'),
					url: urls.pressRoom,
				},
				{
					id: 'suppliers',
					name: t('sections.lm.suppliers'),
					url: urls.providers,
				},
				{
					id: 'cookie-consent',
					name: t('sections.lm.cookies'),
					url: '#',
					buttonClass: 'ot-sdk-show-settings',
				},
			],
		},
		{
			id: 'useful-links-caption',
			name: t('sections.usefulLinks.caption'),
			items: [
				{
					id: 'planner-3d',
					name: t('sections.usefulLinks.3dPlanner'),
					url: urls.planer3d,
					pageType: 'CMS',
				},
				{
					id: 'calendar-designer',
					name: t('sections.usefulLinks.calendarDesigner'),
					url: urls.designService,
					pageType: 'CMS',
				},
				{
					id: 'loyalty-program',
					name: t('sections.usefulLinks.loyaltyProgram'),
					url: urls.loyaltyProgram,
				},
				{
					id: 'gift-card',
					name: t('sections.usefulLinks.giftCard'),
					url: urls.giftCard,
					pageType: 'CMS',
				},
				{
					id: 'majsteria',
					name: t('sections.usefulLinks.majsteria'),
					url: urls.majsteria,
				},
			],
		},
		{
			id: 'customer-service-caption',
			name: t('sections.customerService.caption'),
			items: [
				{
					id: 'contact',
					name: t('sections.customerService.contact'),
					url: urls.contact,
					pageType: 'CONTACT',
				},
				{
					id: 'stores',
					name: t('sections.customerService.stores'),
					url: urls.stores,
					pageType: 'STORES',
				},
				{
					id: 'payment-forms',
					name: t('sections.customerService.paymentForms'),
					url: urls.paymentForms,
					pageType: 'CMS',
				},
				{
					id: 'time-and-delivery-costs',
					name: t('sections.customerService.timeAndDeliveryCosts'),
					url: urls.timeAndDeliveryCosts,
					pageType: 'CMS',
				},
				{
					id: 'returns-and-complaints',
					name: t('sections.customerService.returnsAndComplaints'),
					url: urls.returnsAndComplaints,
					pageType: 'FAQ',
				},
				{
					id: 'regulations',
					name: t('sections.customerService.regulations'),
					url: urls.termsAndConditions,
					pageType: 'CMS',
				},
				{
					id: 'faq',
					name: t('sections.customerService.faq'),
					url: urls.faq,
					pageType: 'FAQ',
				},
			],
		},
		{
			id: 'social-media-caption',
			name: t('sections.socialMedia.caption'),
			items: [
				{
					id: 'facebook',
					name: t('sections.socialMedia.facebook'),
					url: urls.facebookLm,
					icon: 'facebook',
				},
				{
					id: 'youTube',
					name: t('sections.socialMedia.youTube'),
					url: urls.youTubeLm,
					icon: 'youtube',
				},
				{
					id: 'instagram',
					name: t('sections.socialMedia.instagram'),
					url: urls.instagramLm,
					icon: 'instagram',
				},
				{
					id: 'pinterest',
					name: t('sections.socialMedia.pinterest'),
					url: urls.pinterestLm,
					icon: 'pinterest',
				},
				{
					id: 'linkedIn',
					name: t('sections.socialMedia.linkedIn'),
					url: urls.linkedInLm,
					icon: 'linkedin',
				},
				{
					id: 'love-garden',
					name: t('sections.socialMedia.loveGarden'),
					url: urls.facebookLoveGarden,
					icon: 'facebook',
				},
			],
		},
	], [urls])

	return {
		apps: getApps(),
		banners: getBanners(),
		buttons: getButtons(),
		policyLinks: getPolicyLinks(),
		sections: getSections(),
	}
}

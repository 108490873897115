import { useCallback, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { shallowEqual, useSelector } from 'react-redux'
import { compact, isEqual, isNil, isNull } from 'lodash'

import { AppParametersContext } from '~/providers/appParametersProvider'
import { IMainMenuData } from '~/hooks/mainMenu'
import { useMounted } from '~/hooks/mounted'
import { IMainMenuItem } from '~/components/core/layout'
import { PageType } from '~/statics'
import { IRootState } from '~/state/types'

export const useMainMenu = (currentPageType: PageType | null): IMainMenuData => {
	const { inspiration, loyaltyProgram, newspaper, urls } = useContext(AppParametersContext)
	const { asPath } = useRouter()
	const { t } = useTranslation(['header'])
	const { data: preferredStore } = useSelector((state: IRootState) => state.preferredStore, shallowEqual)
	const { isMounted } = useMounted()

	const promotionUrl = !isNull(preferredStore) ? urls.productsStorePromotions : urls.searchForAllPromotions

	const checkIfSelected = useCallback((pageType: PageType, pageUrl?: string): boolean => {
		if (isNull(pageType)) {
			return false
		}

		if (isNil(pageUrl)) {
			return isEqual(pageType, currentPageType)
		}

		return isEqual(pageUrl, asPath)
	}, [asPath, currentPageType])

	const getFirstSection = useCallback((): IMainMenuItem[] => compact([
		{
			id: 'services',
			name: t('menu.services'),
			url: urls.services,
			pageType: 'STORE_SERVICES',
			promotion: false,
			isSelected: checkIfSelected('STORE_SERVICES'),
		},
		{
			id: 'calendar',
			name: t('menu.design'),
			url: urls.designService,
			promotion: false,
			isSelected: checkIfSelected('CMS', urls.designService),
		},
		{
			id: 'newspaper',
			name: t('menu.newspaper'),
			url: urls.newspaperList,
			pageType: 'NEWSPAPER_LIST',
			promotion: false,
			isSelected: checkIfSelected('NEWSPAPER_LIST'),
		},
		isMounted && {
			id: 'sale',
			name: t('menu.sale'),
			url: promotionUrl,
			pageType: 'SEARCH_PRODUCTS',
			promotion: true,
			// check only url, because pageType is the same as in SEARCH_PRODUCTS
			isSelected: checkIfSelected('SEARCH_PRODUCTS', promotionUrl),
		},
	]), [newspaper, urls, currentPageType, asPath, promotionUrl, isMounted])

	const getSecondSection = useCallback((): IMainMenuItem[] => compact([
		!isNil(inspiration) && inspiration.visible && {
			id: 'inspirations',
			name: inspiration.menuName,
			url: inspiration.wwwUrl,
			pageType: 'INSPIRATION_ROOT',
			promotion: false,
			color: inspiration.color,
			isSelected: checkIfSelected('INSPIRATION_ROOT'),
		},
		{
			id: 'advices',
			name: t('menu.advices'),
			url: urls.articles,
			pageType: 'ARTICLE_CATALOG_ROOT',
			promotion: false,
			isSelected: checkIfSelected('ARTICLE_CATALOG_ROOT'),
		},
		!isNil(loyaltyProgram) && {
			id: 'loyalty',
			name: loyaltyProgram.menuName,
			url: loyaltyProgram.redirectionUrl,
			promotion: false,
			// redirect to external site -> should be always false
			isSelected: false,
		},
	]), [inspiration, loyaltyProgram, urls, currentPageType, asPath])

	return {
		firstSection: getFirstSection(),
		secondSection: getSecondSection(),
	}
}

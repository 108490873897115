import getConfig from 'next/config'

import { IBreakpointData } from './types'

const { publicRuntimeConfig } = getConfig()

const buildParams = {
	version: '2.21.0',
	isDevMode: process.env.NODE_ENV === 'development',
	baseApiUrl: publicRuntimeConfig.NEXT_PUBLIC_API_BASE_URL,
	plannerRestUrl: publicRuntimeConfig.NEXT_PUBLIC_PLANNER_REST_URL,
	internalProxyApiServiceUrl: publicRuntimeConfig.NEXT_PUBLIC_INTERNAL_API_PROXY_API_SERVICE,
	internalCmsIntegrationServiceUrl: publicRuntimeConfig.NEXT_PUBLIC_INTERNAL_API_CMS_INTEGRATION_SERVICE,
	internalWebToolsServiceUrl: publicRuntimeConfig.NEXT_PUBLIC_INTERNAL_API_WEB_TOOLS_SERVICE,
	internalTransactionManagerServiceUrl: publicRuntimeConfig.NEXT_PUBLIC_INTERNAL_API_TRANSACTION_MANAGER_SERVICE,
	internalAuthlessOrderService: publicRuntimeConfig.NEXT_PUBLIC_INTERNAL_API_AUTHLESS_ORDER_SERVICE,
	isKobiIntegrationEnabled: publicRuntimeConfig.NEXT_PUBLIC_KOBI_INTEGRATION_ENABLED,
}

const isDeveloperMode = (): boolean => buildParams.isDevMode
const isBrowserMode = (): boolean => typeof window !== 'undefined'
const isProductionEnvironment = (): boolean => publicRuntimeConfig.NEXT_PUBLIC_ENVIRONMENT === 'prod'

const breakpoints: IBreakpointData = {
	smallMobile: 360,
	mediumMobile: 380,
	mobile: 480,
	tablet: 768,
	smallDesktop: 1024,
	desktop: 1200,
	largeDesktop: 1312,
}

const path = {
	images: '/static/images/',
	accountImages: '/static/images/account/',
	maps: '/static/images/maps/',
	errors: '/static/images/errors/',
	giftCard: '/static/images/giftcard/',
	payment: '/static/images/payment/',
	delivery: '/static/images/delivery/',
	cart: '/static/images/cart/',
}

const google = {
	map: {
		apiKey: publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
	},
	reCaptcha: {
		siteKey: publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY,
	},
	analytics: {
		measurementId: publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID,
		measurementId_ccdp: publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID_CCDP,
	},
}

const inPost = {
	geoWidget: {
		token: publicRuntimeConfig.NEXT_PUBLIC_INPOST_GEOWIDGET_TOKEN,
		domain: isProductionEnvironment() ? 'https://geowidget.inpost.pl' : 'https://sandbox-easy-geowidget-sdk.easypack24.net',
	},
}

export const getImagePath = (file: string): string => (
	`${ path.images }${ file }`
)

export const getAccountImagePath = (file: string): string => (
	`${ path.accountImages }${ file }`
)

export const getMapsImagePath = (file: string): string => (
	`${ path.maps }${ file }`
)

export const getErrorsImagePath = (file: string): string => (
	`${ path.errors }${ file }`
)

export const getGiftCardImagePath = (file: string): string => (
	`${ path.giftCard }${ file }`
)

export const getPaymentImagePath = (file: string): string => (
	`${ path.payment }${ file }`
)

export const getDeliveryImagePath = (file: string): string => (
	`${ path.delivery }${ file }`
)

export const getCartImagePath = (file: string): string => (
	`${ path.cart }${ file }`
)

const vars = {
	isDeveloperMode: isDeveloperMode(),
	isBrowserMode: isBrowserMode(),
	isProductionEnvironment: isProductionEnvironment(),
	isKobiIntegrationEnabled: buildParams.isKobiIntegrationEnabled,
	app: {
		apiUrl: `${ buildParams.baseApiUrl }`,
		apiHeaders: {
			'app-platform': 'RWD',
			'X-Api-Key': `${ publicRuntimeConfig.NEXT_PUBLIC_X_API_KEY }`,
			'x-kobi-discover-fragments': true,
			'X-Content-Type-Options': 'nosniff',
		},
		version: buildParams.version,
		services: {
			external: {
				PLANNER_REST_API: buildParams.plannerRestUrl,
				CMS_INTEGRATION_SERVICE: `${ buildParams.baseApiUrl }/cms-integration-service`,
				PROXY_API_SERVICE: `${ buildParams.baseApiUrl }/proxy-api-service`,
				ACCOUNT_SERVICE: `${ buildParams.baseApiUrl }/account-service`,
				AUTH_SERVICE: `${ buildParams.baseApiUrl }/auth-service`,
				CUSTOMER_SERVICE: `${ buildParams.baseApiUrl }/customer-service`,
				CUSTOMER_REVIEW_SERVICE: `${ buildParams.baseApiUrl }/customer-review-service`,
				WEB_TOOLS_SERVICE: `${ buildParams.baseApiUrl }/web-tools-service`,
				CUSTOMER_ORDER_SERVICE: `${ buildParams.baseApiUrl }/customer-order-service`,
				CUSTOMER_PLANNER_SERVICE: `${ buildParams.baseApiUrl }/customer-planner-service`,
				CUSTOMER_PREFERENCE_SERVICE: `${ buildParams.baseApiUrl }/customer-preference-service`,
				TRANSACTION_MANAGER_SERVICE: `${ buildParams.baseApiUrl }/transaction-manager`,
				AUTHLESS_ORDER_SERVICE: `${ buildParams.baseApiUrl }/authless-order-service`,
				AUTHLESS_WEB_INTERACTION_SERVICE: `${ buildParams.baseApiUrl }/authless-web-interaction-service`,
				PICKUP_ORDER_SERVICE: `${ buildParams.baseApiUrl }/pickup-order-service`,
				LOG_SERVICE: `${ buildParams.baseApiUrl }/log-service`,
				CUSTOMER_LOYALTY_INTEGRATION_SERVICE: `${ buildParams.baseApiUrl }/customer-loyalty-integration-service`,
				ADEO_CART_SERVICE: `${ buildParams.baseApiUrl }/adeo-cart-service`,
				OFFER_MANAGER_SERVICE: `${ buildParams.baseApiUrl }/offer-manager`,
				CUSTOMER_CONTACT_SERVICE: `${ buildParams.baseApiUrl }/customer-contact-service`,
				STORE_DATA_SERVICE: `${ buildParams.baseApiUrl }/store-data-service`,
			},
			internal: {
				CMS_INTEGRATION_SERVICE: buildParams.internalCmsIntegrationServiceUrl,
				PROXY_API_SERVICE: buildParams.internalProxyApiServiceUrl,
				WEB_TOOLS_SERVICE: buildParams.internalWebToolsServiceUrl,
				TRANSACTION_MANAGER_SERVICE: buildParams.internalTransactionManagerServiceUrl,
				AUTHLESS_ORDER_SERVICE: buildParams.internalAuthlessOrderService,
			},
		},
	},
	supportedPaymentWays: ['PRZELEWY24_BANK', 'PRZELEWY24_CARD', 'PRZELEWY24_BLIK', 'PRZELEWY24_PAYPO', 'PRZELEWY24_INSTALMENT'],
	google: {
		mapApiKey: google.map.apiKey,
		reCaptchaSiteKey: google.reCaptcha.siteKey,
		analyticsId: google.analytics.measurementId,
	},
	inPost: {
		geoWidgetToken: inPost.geoWidget.token,
		geoWidgetDomain: inPost.geoWidget.domain,
	},
	// the same as in _mixins.scss
	breakpoints,
	grid: {
		viewSizeSmallMobile: `(min-width: ${ breakpoints.smallMobile }px)`,
		viewSizeMediumMobile: `(min-width: ${ breakpoints.mediumMobile }px)`,
		viewSizeMobile: `(min-width: ${ breakpoints.mobile }px)`,
		viewSizeTablet: `(min-width: ${ breakpoints.tablet }px)`,
		viewSizeSmallDesktop: `(min-width: ${ breakpoints.smallDesktop }px)`,
		viewSizeDesktop: `(min-width: ${ breakpoints.desktop }px)`,
		viewSizeLargeDesktop: `(min-width: ${ breakpoints.largeDesktop }px)`,
	},
	gaps: {
		productsCarousel: {
			mobile: 16,
			cmsTablet: 8,
			tablet: 32,
		},
	},
	images: {
		logo: getImagePath('lm-logo.png'),
		loyaltyProgramJoinDom: getAccountImagePath('loyalty-join-dom.png'),
		loyaltyProgramJoinPro: getAccountImagePath('loyalty-join-pro.png'),
		loyaltyLogoDom: getAccountImagePath('loyalty-logo-dom.png'),
		loyaltyLogoPro: getAccountImagePath('loyalty-logo-pro.png'),
		registerLogoDom: getAccountImagePath('register-logo-dom.png'),
		registerLogoPro: getAccountImagePath('register-logo-pro.png'),
		foundation: getAccountImagePath('foundation.png'),
		proBanner: getAccountImagePath('pro-banner.png'),
		pinBlue: getMapsImagePath('pin-blue.png'),
		pinGreen: getMapsImagePath('pin-green.png'),
		locationPoint: getMapsImagePath('location-point.png'),
		notFound: getErrorsImagePath('notFound.png'),
		serverError: getErrorsImagePath('bg-50x.png'),
		bow: getGiftCardImagePath('bow.png'),
		mozaicBow: getGiftCardImagePath('mozaic-bow.png'),
		cab: getImagePath('cab.png'),
		paymentTransfer: getPaymentImagePath('transfer.png'),
		paymentPaypo: getPaymentImagePath('paypo.png'),
		paymentOney: getPaymentImagePath('oney.png'),
		paymentGpay: getPaymentImagePath('gpay.png'),
		paymentCard: getPaymentImagePath('card.png'),
		paymentBlik: getPaymentImagePath('blik.png'),
		paymentApay: getPaymentImagePath('apay.png'),
		paymentCashOnDelivery: getPaymentImagePath('cash-on-delivery.png'),
		cat: getImagePath('cat.png'),
		deliveryTruck: getDeliveryImagePath('truck.png'),
		deliveryCollectionPoint: getDeliveryImagePath('collectionPoint.png'),
		deliveryPackage: getDeliveryImagePath('package.png'),
		deliveryCourierWithCarry: getDeliveryImagePath('courier-with-carry.png'),
		boxConfirm: getCartImagePath('boxConfirm.png'),
		boxFailure: getCartImagePath('boxFailure.png'),
		boxUpdating: getCartImagePath('boxUpdating.png'),
		ordersServicePlaceholder: getAccountImagePath('order-service-placeholder.png'),
		guestAdd: getImagePath('guestAdd.png'),
		guestSuccess: getImagePath('guestSuccess.png'),
		guestFailure: getImagePath('guestFailure.png'),
		planner: getImagePath('planner.png'),
		videoImagePlaceholder: getImagePath('video-image-placeholder.png'),
		loyaltyCard: getImagePath('loyaltyCard.svg'),
	},
	colors: {
		green: '#78be20',
		greenDark: '#69a61c',
		greenDarkBadge: '#005F61',
		greenKobi: '#188803',
		navy: '#00263a',
		creamy: '#f8f8f8',
		navyDark: '#001621',
		tealLight: '#3bb391',
		$tealLight15: 'rgba(59, 179, 145, 15%)',
		teal: '#32997c',
		tealDark: '#005f61',
		red: '#da291c',
		white: '#fff',
		blue: '#0092bc',
		orange: '#ffa300',
		black: '#000',
		black5: 'rgba(0, 0, 0, 5%)',
		black10: 'rgba(0, 0, 0, 10%)',
		black15: 'rgba(0, 0, 0, 15%)',
		black20: 'rgba(0, 0, 0, 20%)',
		black30: 'rgba(0, 0, 0, 30%)',
		black40: 'rgba(0, 0, 0, 40%)',
		black50: 'rgba(0, 0, 0, 50%)',
		black55: 'rgba(0, 0, 0, 55%)',
		black65: 'rgba(0, 0, 0, 65%)',
		black70: 'rgba(0, 0, 0, 70%)',
		gray: '#595959',
		grayLight: '#f2f2f2',
		grayMedium: '#a9a9a9',
		gray15: 'rgba(126, 126, 126, 15%)',
		gray55: 'rgba(126, 126, 126, 55%)',
		alertSuccess: '#e0f6e0',
		alertSuccessText: '#6f926f',
		alertDanger: '#fbeaea',
		alertDangerText: '#9e5656',
		alertWarning: '#ffeed0',
		alertWarningText: '#806029',
		mozaicGrey600: '#666666',
		mozaicGrey800: '#333333',
		mozaicGrey900: '#191919',
		mozaicSecondaryGreen500: '#46a610',
		mozaicPrimary01600: '#188803',
		mozaicDanger600: '#c61112',
	},
	i18n: {
		defaultLocale: 'pl',
	},
	emails: {
		store: 'e-sklep@leroy-merlin.pl',
	},
	eventKeys: {
		enter: 'Enter',
	},
	seo: {
		alt: 'Leroy Merlin',
	},
	lastViewed: {
		productsLimit: 16,
	},
	comparison: {
		maxProducts: 4,
	},
	timers: {
		oneYearMilliseconds: 31540000000,
	},
	map: {
		lat: 52.215933,
		lng: 19.134422,
	},
}

export { vars }

import React, { ReactElement, useCallback } from 'react'
import { SwiperOptions } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import { chunk, map } from 'lodash'

import { Carousel } from '~/components/core/carousel'
import { CmsBlockProductBlock, ICmsBlockProductListingRotatorProps } from '~/components/core/cmsBlock'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { IProductSimple } from '~/api/dataTypes/product'

import styles from './CmsBlockProductListingRotator.module.scss'

const CmsBlockProductListingRotator = (props: ICmsBlockProductListingRotatorProps): ReactElement => {
	const { productList, maxLinesQuantity } = props
	const { isTablet } = useMediaQuery()

	const overrideCarouselParams: SwiperOptions = {
		spaceBetween: 16,
		slidesPerView: isTablet ? 4 : 2,
	}

	const renderItems = useCallback((): ReactElement[] | ReactElement => {
		if (isTablet) {
			const splitArrays = chunk(productList, maxLinesQuantity)

			return map(splitArrays, (splitArray: IProductSimple[]) => (
				<SwiperSlide key={ splitArray[0].lmReference } className={ styles.slide }>
					{ map(splitArray, (product: IProductSimple, index: number) => (
						<CmsBlockProductBlock
							key={ product.lmReference }
							product={ product }
							position={ index }
						/>
					)) }
				</SwiperSlide>
			))
		}

		return map(productList, (product: IProductSimple, index: number) => (
			<SwiperSlide key={ product.lmReference }>
				<CmsBlockProductBlock
					product={ product }
					position={ index }
				/>
			</SwiperSlide>
		))
	}, [productList, isTablet])

	return (
		<div className={ styles.wrapper }>
			<Carousel
				isWithoutGradient
				theme={ {
					skipContent: { wrapper: styles.skipContent },
					wrapper: styles.carousel,
					swiper: styles.swiper,
				} }
				params={ overrideCarouselParams }
			>
				{ renderItems() }
			</Carousel>
		</div>
	)
}

export { CmsBlockProductListingRotator }

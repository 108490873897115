import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { isEqual, isNull, isUndefined, map, includes, isNil } from 'lodash'

import { Image } from '~/components/core/image'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { CmsBlockProductBlock, CmsBlockProductTitle, CmsBlockProductTwoRowsRotator, ICmsBlockProductTwoRowsProps } from '~/components/core/cmsBlock'
import { Link } from '~/components/core/link'
import { DATA_TESTID } from '~/utils/dataTestId'
import { CmsPageBlockDataImageBlockAlignType } from '~/api/dataTypes/cms'
import { IProductSimple } from '~/api/dataTypes/product'

import styles from './CmsBlockProductTwoRows.module.scss'

const CmsBlockProductTwoRows = (props: ICmsBlockProductTwoRowsProps): ReactElement | null => {
	const { content } = props
	const { isTablet } = useMediaQuery()

	const { title, hideHeader, labelUrl, labelName, imageBlockAlign, wideImageUrl, narrowImageUrl, productList, rotatorActive, wwwUrl } = content
	const imageUrl = isTablet ? wideImageUrl : narrowImageUrl

	const renderProducts = useCallback((): ReactElement | ReactElement[] => {
		if (rotatorActive && !isUndefined(productList)) {
			return <CmsBlockProductTwoRowsRotator productList={ productList } />
		}

		return map(productList, (product: IProductSimple, index: number) => {
			const isSecondOrThirdProduct = includes([2, 3], index)
			const additionalProductsClass = isEqual(imageBlockAlign, 'RIGHT' as CmsPageBlockDataImageBlockAlignType) && isSecondOrThirdProduct ? { additionalClass: styles.productsAlignLeft } : { }

			return (
				<CmsBlockProductBlock
					key={ `${ title }-${ product.lmReference }` }
					product={ product }
					position={ index }
					// eslint-disable-next-line react/jsx-props-no-spreading
					{ ...additionalProductsClass }
				/>
			)
		})
	}, [productList, rotatorActive])

	const renderImage = useCallback((): ReactElement | null => {
		const imageWrapperClass = classNames({
			[styles.imageWrapper]: true,
			[styles.alignRight]: isEqual(imageBlockAlign, 'RIGHT' as CmsPageBlockDataImageBlockAlignType),
		})

		const alt = title || ''

		if (isNil(imageUrl)) {
			return null
		}

		if (isUndefined(wwwUrl)) {
			return (
				<div className={ imageWrapperClass }>
					<Image
						width={ 0 }
						height={ 0 }
						src={ imageUrl }
						alt={ alt }
						className={ styles.img }
					/>
				</div>
			)
		}

		return (
			<Link
				theme={ { wrapper: imageWrapperClass } }
				href={ wwwUrl }
				dataTestId={ DATA_TESTID.CMS_PAGES.IMAGE }
				ariaLabel={ title }
			>
				<Image
					width={ 0 }
					height={ 0 }
					src={ imageUrl }
					alt={ alt }
					className={ styles.img }
				/>
			</Link>
		)
	}, [imageUrl, wwwUrl, title, imageBlockAlign])

	if (isNull(imageUrl)) {
		return null
	}

	return (
		<div className={ styles.wrapper }>
			<CmsBlockProductTitle
				title={ title }
				hideHeader={ hideHeader }
				labelUrl={ labelUrl }
				labelName={ labelName }
			/>

			<div className={ styles.content }>
				{ renderImage() }

				{ renderProducts() }
			</div>
		</div>
	)
}

export { CmsBlockProductTwoRows }

import https from 'https'

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { includes, isEqual, isNil, isUndefined, omit } from 'lodash'

import { vars } from '~/statics'
import { ApiServiceType } from '~/api/dataTypes/axios'
import { AppDispatch, store } from '~/state/store'
import { logAxiosError, logAxiosResponse } from '~/utils/logger'
import { LS_KEY_ADEO_AUTH_USER, LS_KEY_CALENDAR_CART_UUID, LS_KEY_SHOULD_BE_LOGIN_BY_ADEO } from '~/utils/constants'
import { getAuthenticateRefreshToken, setAdeoExtractTokens, setAdeoRefreshTokens } from '~/actions/auth'
import { IGetAdeoCookieAuthData } from '~/utils/types'
import { getCartUuid } from '~/utils/cart'
import { localStorageGet } from '~/utils/storage'
import { AdeoAuthDataType } from '~/state/reducers/authReducer'
import { isValidKobiJwtToken } from '~/utils/token'

const authorizedServices: ApiServiceType[] = ['CUSTOMER_SERVICE', 'CUSTOMER_ORDER_SERVICE', 'CUSTOMER_REVIEW_SERVICE', 'CUSTOMER_PLANNER_SERVICE', 'CUSTOMER_PREFERENCE_SERVICE', 'CUSTOMER_LOYALTY_INTEGRATION_SERVICE']

const request: AxiosInstance = axios.create({
	withCredentials: false,
	baseURL: vars.app.apiUrl,
	timeout: 130000,
	headers: vars.app.apiHeaders,
	httpsAgent: new https.Agent({
		rejectUnauthorized: false,
	}),
	validateStatus: (status: number): boolean => {
		return status >= 200 && status < 300
	},
})

request.interceptors.request.use((config: AxiosRequestConfig) => {
	const {
		service = 'PROXY_API_SERVICE',
		isInternal = false,
		restAuthorization = false,
		cartUuid = null,
		calendarCartUuid = null,
		withStoreCode = false,
	} = config
	const state = store?.getState()

	const { app, isDeveloperMode } = vars
	const { external, internal } = app.services
	const baseURL = isDeveloperMode ? external[service] : isInternal ? internal[service] : external[service]

	if (isInternal || !isDeveloperMode) {
		config.headers = { ...omit(config.headers, 'X-Api-Key') }
	}

	if (!isNil(state)) {
		const shouldBeLoginByAdeo = localStorageGet(LS_KEY_SHOULD_BE_LOGIN_BY_ADEO)
		const adeoAccessToken: string | undefined = localStorageGet<AdeoAuthDataType>(LS_KEY_ADEO_AUTH_USER)?.accessToken

		const accessToken = shouldBeLoginByAdeo ? adeoAccessToken : state.auth.data?.access_token

		const currentCartUuid = getCartUuid()

		const currentCalendarCartUuid = localStorageGet(LS_KEY_CALENDAR_CART_UUID)

		if (!isUndefined(config.headers)) {
			if (!isNil(accessToken) && (includes(authorizedServices, service) || restAuthorization)) {
				config.headers.authorization = `Bearer ${ accessToken }`
			}

			if (!isNil(state.preferredStore.data)) {
				const { storeCode, id } = state.preferredStore.data

				config.headers.storeCode = storeCode
				config.headers.storeId = id

				if (withStoreCode) {
					config.params = { storeCode, ...config.params }
				}
			}
		}

		if (!isNil(cartUuid) && !isNil(currentCartUuid)) {
			config.params = { ...config.params, cartUuid: currentCartUuid }
		}

		if (!isNil(calendarCartUuid) && !isNil(currentCalendarCartUuid)) {
			config.params = { ...config.params, calendarCartUuid: currentCalendarCartUuid }
		}
	}

	return {
		retry: includes(authorizedServices, service),
		...config,
		baseURL,
	}
})

request.interceptors.response.use(
	(response: AxiosResponse) => logAxiosResponse(response),
	(error: AxiosError) => {
		const { config, response } = error
		const dispatch = store?.dispatch as AppDispatch
		const { service, retry } = config
		const { isDeveloperMode } = vars
		const state = store?.getState()

		const shouldBeLoginByAdeo = localStorageGet(LS_KEY_SHOULD_BE_LOGIN_BY_ADEO)

		const shouldRetry = isEqual(response?.status, 401) && includes(authorizedServices, service) && !isNil(dispatch) && retry
		const shouldRetryByAdeo = shouldRetry && shouldBeLoginByAdeo

		if (shouldRetry && !shouldBeLoginByAdeo) {
			// eslint-disable-next-line @typescript-eslint/typedef
			return new Promise((resolve, reject) => {
				setTimeout(async () => {
					config.retry = false

					return await dispatch(getAuthenticateRefreshToken()).then(async () => {
						await request(config).then(resolve).catch(reject)
					}).catch(reject)
				})
			})
		} else if (shouldRetryByAdeo) {
			// eslint-disable-next-line @typescript-eslint/typedef
			return new Promise((resolve, reject) => {
				setTimeout(async () => {
					config.retry = false

					// TODO: commented due to issue PLEDEV-8596
					// const { data }: AxiosResponse<IGetAdeoCookieAuthData> = await axios.get('/api/cookies')
					// const { cookies } = data
					// const { kobiJwt, idToken } = cookies || {}

					// const hasTokenChanged = !isUndefined(kobiJwt) && !isEqual(kobiJwt, state?.auth.adeo?.kobiJwt)

					// if (hasTokenChanged) {
					// return await dispatch(setAdeoExtractTokens({ kobiJwt })).then(async () => {
					// await request(config).then(resolve).catch(reject)
					// }).catch(reject)
					// } else if (!isUndefined(kobiJwt) && !isUndefined(idToken) && !isValidKobiJwtToken(kobiJwt)) {
					// return await dispatch(setAdeoRefreshTokens({ kobiJwt, idToken })).then(async () => {
					// await request(config).then(resolve).catch(reject)
					// }).catch(reject)
					// }

					return await request(config).then(resolve).catch(reject)
				})
			})
		}

		return isDeveloperMode ? logAxiosError(error) : Promise.reject(error)
	}
)

declare module 'axios' {
	export interface AxiosRequestConfig {
		service?: ApiServiceType
		retry?: boolean
		isInternal?: boolean
		restAuthorization?: boolean
		cartUuid?: boolean
		calendarCartUuid?: boolean
		withStoreCode?: boolean
	}
}

export { request }

import React, { ReactElement, useCallback, HTMLAttributeAnchorTarget } from 'react'
import { isEmpty, isUndefined, isEqual } from 'lodash'

import { Image } from '~/components/core/image'
import { Link } from '~/components/core/link'
import { ICmsBlockImageProps } from '~/components/core/cmsBlock'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { CmsPageBlockDataLinkTargetType } from '~/api/dataTypes/cms'

import styles from './CmsBlockImage.module.scss'

const CmsBlockImage = (props: ICmsBlockImageProps): ReactElement | null => {
	const { content, name } = props
	const { isTablet } = useMediaQuery()

	const renderContent = useCallback(() => {
		const { image, alternativeImage, redirectUrl, linkTarget } = content

		if (isUndefined(image) || isUndefined(alternativeImage)) {
			return null
		}

		const imageObj = isTablet ? image : alternativeImage

		if (!isEmpty(redirectUrl)) {
			const target: HTMLAttributeAnchorTarget = isEqual(linkTarget, 'BLANK' as CmsPageBlockDataLinkTargetType) ? '_blank' : '_self'

			return (
				<Link
					href={ redirectUrl }
					ariaLabel={ name }
					target={ target }
					theme={ { wrapper: styles.wrapper } }
				>
					<Image
						width={ 0 }
						height={ 0 }
						alt={ name }
						src={ imageObj.url }
						className={ styles.image }
					/>
				</Link>
			)
		}

		return (
			<Image
				width={ 0 }
				height={ 0 }
				alt={ name }
				src={ imageObj.url }
				className={ styles.image }
			/>
		)
	}, [content, name, isTablet])

	return (
		<div className={ styles.wrapper }>
			{ renderContent() }
		</div>
	)
}

export { CmsBlockImage }

import { isNull, isFunction } from 'lodash'
import { NextRouter } from 'next/router'

import { IHandleHtmlViewLinkParams } from '~/components/core/htmlView'

const handleKobiRedirect = async (router: NextRouter, link: string, isKobiIntegrationEnabled: boolean): Promise<void> => {
	if (isKobiIntegrationEnabled) {
		if (link.startsWith('http')) {
			window.location.href = link
		} else {
			window.location.pathname = link
		}
	} else {
		await router.push(link)
	}
}

export const handleHtmlViewLink = async (params: IHandleHtmlViewLinkParams): Promise<void> => {
	const { link, onLinkClick, event, router, isKobiIntegrationEnabled } = params

	if (!isNull(link) && isFunction(onLinkClick)) {
		await onLinkClick(link, event)
	} else if (!isNull(link) && !isFunction(onLinkClick)) {
		event.preventDefault()
		await handleKobiRedirect(router, link, isKobiIntegrationEnabled)
	}
}

export const getHtmlWithoutScripts = (content: string): string => {
	return content.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '')
}

import React, { useCallback, ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { map } from 'lodash'

import { DATA_TESTID } from '~/utils/dataTestId'
import { IFooterNavigationPolicyLink } from '~/hooks/footerNavigation'
import { useDate } from '~/hooks/date'
import { FooterAppVersion, IFooterBottomBarProps } from '~/components/core/layout'
import { Link } from '~/components/core/link'

import styles from './FooterBottomBar.module.scss'

const FooterBottomBar = (props: IFooterBottomBarProps): ReactElement => {
	const {
		policyLinks,
		theme = {},
	} = props
	const { t } = useTranslation(['footer'])
	const { formatDate, getTodayDate, getYearFormat } = useDate()

	const currentYear = formatDate(getTodayDate(), getYearFormat())

	const renderPolicyLinks = useCallback((): ReactElement[] => (
		map(policyLinks, (policyLink: IFooterNavigationPolicyLink): ReactElement => {
			const { label, url, id } = policyLink

			return (
				<Link
					key={ id }
					href={ url }
					additionalClass={ styles.label }
					ariaLabel={ label }
				>
					{ label }
				</Link>
			)
		})
	), [policyLinks])

	const wrapperClass = classNames(styles.wrapper, theme.wrapper)

	return (
		<div className={ wrapperClass }>
			<div className={ styles.copyright } dangerouslySetInnerHTML={ { __html: t('copyrights', { currentYear }) } } />

			<FooterAppVersion />

			<div className={ styles.links } data-testid={ DATA_TESTID.FOOTER.BOTTOM_BAR_LINKS }>
				{ renderPolicyLinks() }
			</div>
		</div>
	)
}

export { FooterBottomBar }

import React, { ReactElement, useCallback } from 'react'
import { SwiperSlide } from 'swiper/react'
import { map, chunk } from 'lodash'

import { IProductSimple } from '~/api/dataTypes/product'
import { Carousel } from '~/components/core/carousel'
import { CMS_BLOCK_TWO_ROWS_PRODUCTS_CAROUSEL_PARAMS, CmsBlockProductBlock, ICmsBlockProductTwoRowsRotatorProps } from '~/components/core/cmsBlock'
import { useMediaQuery } from '~/hooks/mediaQuery'

import styles from './CmsBlockProductTwoRowsRotator.module.scss'

const CmsBlockProductTwoRowsRotator = (props: ICmsBlockProductTwoRowsRotatorProps): ReactElement => {
	const { productList } = props
	const { isTablet } = useMediaQuery()

	const renderItems = useCallback((): ReactElement[] | ReactElement => {
		if (isTablet) {
			const splitArrays = chunk(productList, 2)

			return map(splitArrays, (splitArray: IProductSimple[]) => (
				<SwiperSlide key={ splitArray[0].lmReference } className={ styles.slide }>
					{ map(splitArray, (product: IProductSimple, index: number) => (
						<CmsBlockProductBlock
							key={ `${ splitArray[0].lmReference }-${ index }` }
							product={ product }
							position={ index }
							additionalClass={ styles.block }
						/>
					)) }
				</SwiperSlide>
			))
		}

		return map(productList, (product: IProductSimple, index: number) => (
			<SwiperSlide key={ `${ product.lmReference }-${ index }` } className={ styles.slide }>
				<CmsBlockProductBlock
					product={ product }
					position={ index }
				/>
			</SwiperSlide>
		))
	}, [productList, isTablet])

	return (
		<div className={ styles.wrapper }>
			<Carousel
				isWithoutGradient
				theme={ {
					skipContent: { wrapper: styles.skipContent },
					wrapper: styles.carousel,
					swiper: styles.swiper,
				} }
				params={ CMS_BLOCK_TWO_ROWS_PRODUCTS_CAROUSEL_PARAMS }
			>
				{ renderItems() }
			</Carousel>
		</div>
	)
}

export { CmsBlockProductTwoRowsRotator }

import React, { ReactElement, useCallback } from 'react'
import { isString, isObject } from 'lodash'

import { CmsBlockHtml, CmsBlockProduct, CmsBlockImage, ICmsBlockProps } from '~/components/core/cmsBlock'

const CmsBlock = (props: ICmsBlockProps): ReactElement | null => {
	const { content, type, name } = props

	const RenderBlock = useCallback((): ReactElement | null => {
		switch (type) {
			case 'HTML':
				return isString(content) ? <CmsBlockHtml content={ content } /> : null
			case 'IMAGE':
				return isObject(content) ? <CmsBlockImage content={ content } name={ name } /> : null
			case 'PRODUCT':
				return isObject(content) ? <CmsBlockProduct content={ content } /> : null
			default:
				return null
		}
	}, [content, type, name])

	return (
		<RenderBlock />
	)
}

export { CmsBlock }

import React, { ReactElement, useCallback } from 'react'
import { SwiperSlide } from 'swiper/react'
import { map } from 'lodash'

import { Carousel } from '~/components/core/carousel'
import { CMS_BLOCK_ONE_ROW_PRODUCTS_CAROUSEL_PARAMS, CmsBlockProductBlock, ICmsBlockProductOneRowRotatorProps } from '~/components/core/cmsBlock'
import { IProductSimple } from '~/api/dataTypes/product'

import styles from './CmsBlockProductOneRowRotator.module.scss'

const CmsBlockProductOneRowRotator = (props: ICmsBlockProductOneRowRotatorProps): ReactElement => {
	const { productList } = props

	const renderItem = useCallback((): ReactElement[] => (
		map(productList, (product: IProductSimple, index: number) => {
			return (
				<SwiperSlide key={ product.lmReference } className={ styles.slide }>
					<CmsBlockProductBlock
						product={ product }
						position={ index }
					/>
				</SwiperSlide>
			)
		})
	), [productList])

	return (
		<div className={ styles.wrapper }>
			<Carousel
				isWithoutGradient
				theme={ {
					skipContent: { wrapper: styles.skipContent },
					wrapper: styles.carousel,
				} }
				params={ CMS_BLOCK_ONE_ROW_PRODUCTS_CAROUSEL_PARAMS }
			>
				{ renderItem() }
			</Carousel>
		</div>
	)
}

export { CmsBlockProductOneRowRotator }

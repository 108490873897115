import React, { useCallback, ReactElement } from 'react'
import { isUndefined, map } from 'lodash'

import { CmsBlockProductTitle, CmsBlockProductListingRotator, ICmsBlockProductListingProps } from '~/components/core/cmsBlock'
import { ProductBlock } from '~/components/core/productBlock'
import { IProductSimple } from '~/api/dataTypes/product'

import styles from './CmsBlockProductListing.module.scss'

const CmsBlockProductListing = (props: ICmsBlockProductListingProps): ReactElement => {
	const { content } = props

	const { title, productList, hideHeader, labelName, labelUrl, maxLinesQuantity, rotatorActive } = content

	const renderProducts = useCallback((): ReactElement => {
		if (rotatorActive && !isUndefined(productList) && !isUndefined(maxLinesQuantity)) {
			return <CmsBlockProductListingRotator maxLinesQuantity={ maxLinesQuantity } productList={ productList } />
		}

		return (
			<div className={ styles.productList }>
				{ map(productList, (product: IProductSimple, index: number) => (
					<div key={ product.lmReference } className={ styles.product }>
						<ProductBlock
							hasWishList
							product={ product }
							position={ index }
						/>
					</div>
				)) }
			</div>
		)
	}, [productList, rotatorActive, maxLinesQuantity])

	return (
		<div className={ styles.wrapper }>
			<CmsBlockProductTitle
				title={ title }
				hideHeader={ hideHeader }
				labelUrl={ labelUrl }
				labelName={ labelName }
			/>

			{ renderProducts() }
		</div>
	)
}

export { CmsBlockProductListing }

import React, { ReactElement, useCallback } from 'react'
import classNames from 'classnames'
import { isEqual, isNil, isNull, isUndefined, map } from 'lodash'

import { CmsBlockProductBlock, CmsBlockProductTitle, CmsBlockProductTwoRowsRotator, ICmsBlockProductBigProps } from '~/components/core/cmsBlock'
import { CmsPageBlockDataImageBlockAlignType } from '~/api/dataTypes/cms'
import { IProductSimple } from '~/api/dataTypes/product'

import styles from './CmsBlockProductBig.module.scss'

const CmsBlockProductBig = (props: ICmsBlockProductBigProps): ReactElement | null => {
	const { content } = props

	const { title, hideHeader, labelName, labelUrl, imageBlockAlign, productList, rotatorActive, firstBigProduct, imageUrl } = content

	const renderProducts = useCallback((): ReactElement | ReactElement[] => {
		if (rotatorActive && !isUndefined(productList)) {
			return <CmsBlockProductTwoRowsRotator productList={ productList } />
		}

		return map(productList, (product: IProductSimple, index: number) => {
			const isSecondOrThirdProducts = isEqual(index, 2) || isEqual(index, 3)
			const additionalProductsClass = isEqual(imageBlockAlign, 'RIGHT' as CmsPageBlockDataImageBlockAlignType) && isSecondOrThirdProducts ? { additionalClass: styles.productsAlignLeft } : { }

			return (
				<CmsBlockProductBlock
					key={ `${ title }-${ product.lmReference }` }
					product={ product }
					position={ index }
					// eslint-disable-next-line react/jsx-props-no-spreading
					{ ...additionalProductsClass }
				/>
			)
		})
	}, [productList, rotatorActive])

	if (isNull(imageUrl) || isNil(firstBigProduct)) {
		return null
	}

	const imageWrapperClass = classNames({
		[styles.imageWrapper]: true,
		[styles.alignRight]: isEqual(imageBlockAlign, 'RIGHT' as CmsPageBlockDataImageBlockAlignType),
	})

	return (
		<div className={ styles.wrapper }>
			<CmsBlockProductTitle
				title={ title }
				hideHeader={ hideHeader }
				labelUrl={ labelUrl }
				labelName={ labelName }
			/>

			<div className={ styles.content }>
				<div className={ imageWrapperClass }>
					<CmsBlockProductBlock
						product={ firstBigProduct }
						overrideImageSrc={ imageUrl }
						additionalClass={ styles.bigProduct }
					/>
				</div>

				{ renderProducts() }
			</div>
		</div>
	)
}

export { CmsBlockProductBig }
